import {
  createEvent,
  createStore,
  sample,
  type Domain,
  type Json,
} from 'effector'

type Serializer<T> =
  | 'ignore'
  | {
      write: (state: T) => Json
      read: (json: Json) => T
    }

type UpdateFilter<T> = (update: T, current: T) => boolean

/**
 * @deprecated use `createState`
 */
export function createStateOld<T>(config: {
  sid?: string
  initial: T
  serialize?: Serializer<T>
  updateFilter?: UpdateFilter<T>
  name?: {
    value?: string
    set?: string
    reset?: string
  }
  domain?: Domain
}) {
  const { sid, name, domain, updateFilter, serialize, initial } = config

  const $value = createStore(initial, {
    sid: sid ? `value-${sid}` : sid,
    name: name?.value,
    domain,
    serialize,
    updateFilter,
  })

  const set = createEvent<T>({
    sid: sid ? `set-${sid}` : sid,
    name: name?.set,
    domain,
  })

  const reset = createEvent({
    sid: sid ? `reset-${sid}` : sid,
    name: name?.reset,
    domain,
  })

  sample({
    clock: set,
    target: $value,
  })

  sample({
    clock: reset,
    target: $value.reinit,
  })

  const unitShape = { value: $value, set, reset }

  const unitShapeProtocol = () => unitShape

  return {
    '@@unitShape': unitShapeProtocol,
    $value,
    set,
    reset,
  }
}
