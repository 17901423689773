import {
  concurrency,
  createMutation,
  createQuery,
  keepFresh,
} from '@farfetched/core'
import type { EventPayload } from 'effector'
import { combine, createEvent, createStore, restore, sample } from 'effector'
import { reset } from 'patronum'
import { METRICS } from '~/api/constants'
import {
  getChannelParticipantsBaseFx,
  inviteParticipantBaseFx,
} from '~/api/manage.effects'
import type { ApiType } from '~/api/types'
import { createDisclosureAtomImpl } from '~/shared/lib/factories/create-disclosure'
import { $currentUserChannelsWithAccesses } from '~/user/model'
import { changeChannelParticipantRoleSuccessFinished } from './change-participant-role/model'
import { channelParticipantSuccessDeleted } from './delete-participant-role/model'

export const channelParticipantsModal = createDisclosureAtomImpl()

export const channelParticipantsQuery = createQuery({
  effect: getChannelParticipantsBaseFx,
  initialData: [],
})

concurrency(channelParticipantsQuery, {
  strategy: 'TAKE_LATEST',
})

export const selectRoleModal = createDisclosureAtomImpl()
export const $selectedRole = createStore<
  ApiType<'ChannelRoleSchema'>['role'] | null
>(null)
export const roleSelected = createEvent<ApiType<'ChannelRoleSchema'>['role']>()

export const selectChannelsModal = createDisclosureAtomImpl()
export const $selectedChannel = createStore<
  ApiType<'ChannelSchema'>['channel'] | null
>(null)

export const $selectedChannelAccessesMetrics = createStore<
  NonNullable<ApiType<'TariffSchema'>['metrics']>
>([])
export const channelSelected =
  createEvent<Pick<ApiType<'ChannelSchema'>['channel'], 'id'>>()

export const addParticipantClicked = createEvent()

export const codeModal = createDisclosureAtomImpl()

export const selectChannelsClicked = createEvent()
export const selectChannelsBackClicked = createEvent()
export const saveSelectedParticipantsClicked = createEvent()
export const $selectedChannelsIds = createStore<
  ApiType<'ChannelRoleSchema'>['channel_id'][]
>([])
export const channelIdSelected =
  createEvent<ApiType<'ChannelRoleSchema'>['channel_id']>()

export const inviteParticipantMutation = createMutation({
  effect: inviteParticipantBaseFx,
})

const inviteSuccess = sample({
  clock: inviteParticipantMutation.finished.success,
  fn: ({ result }) => result?.code ?? null,
})

export const $inviteCode = restore(inviteSuccess, null)

export const generateCodeClicked = createEvent()

export const $generateIsAvailable = combine(
  [$selectedRole, $selectedChannelsIds],
  ([role, channels]) => !!role && channels.length > 0,
)

sample({
  clock: channelSelected,
  source: $currentUserChannelsWithAccesses,
  fn: (userChannels, { id }) =>
    userChannels.find(({ channel }) => channel.id === id)?.channel ?? null,
  target: $selectedChannel,
})

sample({
  clock: channelSelected,
  source: $currentUserChannelsWithAccesses,
  fn: (userChannels, { id }) =>
    userChannels.find(({ channel }) => channel.id === id)?.channel.access
      ?.tariff.metrics ?? [],
  target: $selectedChannelAccessesMetrics,
})

sample({
  clock: channelSelected,
  target: channelParticipantsModal.open,
})

sample({
  clock: channelSelected,
  fn: ({ id }) =>
    ({
      params: {
        path: {
          channel_id: id,
        },
      },
    }) satisfies EventPayload<typeof channelParticipantsQuery.start>,
  target: channelParticipantsQuery.start,
})

keepFresh(channelParticipantsQuery, {
  triggers: [
    channelParticipantSuccessDeleted,
    changeChannelParticipantRoleSuccessFinished,
  ],
})

sample({
  clock: addParticipantClicked,
  target: [channelParticipantsModal.close, selectRoleModal.open] as const,
})

sample({
  clock: channelParticipantsModal.closed,
  target: channelParticipantsQuery.reset,
})

sample({
  clock: roleSelected,
  target: $selectedRole,
})

sample({
  clock: selectChannelsClicked,
  target: [selectRoleModal.open, selectChannelsModal.open] as const,
})

sample({
  clock: selectChannelsBackClicked,
  target: [selectChannelsModal.close, selectRoleModal.open] as const,
})

sample({
  clock: channelIdSelected,
  source: $selectedChannelsIds,
  fn: (currentChannelsIds, newChannelId) => {
    const isExists = !!currentChannelsIds.includes(newChannelId)
    if (isExists) {
      return currentChannelsIds.filter(id => id !== newChannelId)
    } else {
      return [...currentChannelsIds, newChannelId]
    }
  },
  target: $selectedChannelsIds,
})

sample({
  clock: saveSelectedParticipantsClicked,
  target: [selectChannelsModal.close, selectRoleModal.open] as const,
})

sample({
  clock: generateCodeClicked,
  source: { channelsIds: $selectedChannelsIds, role: $selectedRole },
  fn: ({ channelsIds, role }) => {
    return {
      body: channelsIds.map(channel_id => ({
        channel_id,
        role: role as ApiType<'ChannelRoleSchema'>['role'],
      })),
    } satisfies EventPayload<typeof inviteParticipantMutation.start>
  },
  target: inviteParticipantMutation.start,
})

sample({
  clock: inviteParticipantMutation.finished.success,
  target: [selectRoleModal.close, codeModal.open] as const,
})

reset({
  clock: codeModal.close,
  target: [
    $selectedChannel,
    $selectedRole,
    $selectedChannelsIds,
    $selectedChannelAccessesMetrics,
  ],
})

export const $canAddUsers = combine(
  [
    $selectedChannelAccessesMetrics,
    channelParticipantsQuery.$status,
    channelParticipantsQuery.$data,
  ],
  ([availableMetrics, channelParticipantsStatus, participants]) => {
    const usersMetric = availableMetrics.find(
      ({ name }) => name === METRICS.users,
    )
    if (!usersMetric) return false
    const isLoading =
      channelParticipantsStatus === 'initial' ||
      channelParticipantsStatus === 'pending'
    if (isLoading) return false
    return (
      participants.filter(({ role }) => role !== 'superadmin').length <
      usersMetric.amount
    )
  },
)

export const $canEditOrRemoveParticipantRole = combine(
  [$currentUserChannelsWithAccesses, $selectedChannel],
  ([userChannels, selectedChannel]) => {
    const acceptedRoles = [
      'admin',
      'owner',
      'superadmin',
    ] satisfies ApiType<'Roles'>[]
    const channel = userChannels.find(
      ({ channel }) => channel.id === selectedChannel?.id,
    )
    return acceptedRoles.includes(channel?.role ?? '')
  },
)

export const $participantsList = combine(
  [
    channelParticipantsQuery.$data,
    $currentUserChannelsWithAccesses,
    $selectedChannel,
  ],
  ([participants, currentUserChannels, selectedChannel]) => {
    const user = currentUserChannels.find(
      ({ channel }) => channel.id === selectedChannel?.id,
    )

    if (!user) return []

    const { account_id, role } = user

    /** Суперадмины могут видеть всех пользователей канала */
    if (role === 'superadmin') {
      return participants
    }

    return participants.filter(participant => {
      /** Пользователь может видеть свою роль */
      if (participant.id === account_id) {
        return true
      } else {
        return participant.role !== 'superadmin'
      }
    })
  },
)

export const profileChannelsAdminsSectionClicked = createEvent()

export const profileChannelsAddChannelClicked = createEvent()
