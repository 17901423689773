import { createMutation } from '@farfetched/core'
import type { EventPayload } from 'effector'
import { combine, createEvent, restore, sample } from 'effector'
import { changeChannelParticipantRoleBaseFx } from '~/api/manage.effects'
import type { ApiType } from '~/api/types'
import { createDisclosureAtomImpl } from '~/shared/lib/factories/create-disclosure'
import { createStateOld } from '~/shared/lib/factories/create-state.old'
import { translateRole } from '~/shared/lib/translate-role'

export const changeChannelParticipantRoleModal = createDisclosureAtomImpl()

export const changeChannelParticipantRoleMutation = createMutation({
  effect: changeChannelParticipantRoleBaseFx,
})

export const changeChannelParticipantRoleSuccessFinished =
  changeChannelParticipantRoleMutation.finished.success

export const changeChannelParticipantRoleClicked = createEvent<{
  user_id: ApiType<'AccountRoleSchema'>['id']
  channel_id: ApiType<'ChannelSchema'>['channel']['id']
  role: ApiType<'Roles'>
}>()

export const changeChannelParticipantRoleConfirmed = createEvent()

export const changeChannelParticipantRoleCanceled = createEvent()

export const $selectedChannelParticipantToChangeRole = restore(
  changeChannelParticipantRoleClicked,
  null,
)

export type AvailableRole = Exclude<ApiType<'Roles'>, 'superadmin' | 'owner'>

const availableRoles: AvailableRole[] = ['admin', 'analytic', 'redactor']

export const $availableRolesOptions = combine(
  [$selectedChannelParticipantToChangeRole],
  ([selected]) =>
    availableRoles
      .filter(role => role !== selected?.role)
      .map(role => ({
        value: role,
        label: translateRole(role),
      })),
)

export const selectedRole = createStateOld<AvailableRole | null>({
  initial: null,
})

sample({
  clock: changeChannelParticipantRoleClicked,
  target: changeChannelParticipantRoleModal.open,
})

sample({
  clock: changeChannelParticipantRoleConfirmed,
  source: {
    params: $selectedChannelParticipantToChangeRole,
    role: selectedRole.$value,
  },
  filter: ({ params, role }) => params !== null && role !== null,
  fn: ({ params, role }) =>
    ({
      params: {
        path: {
          channel_id: params!.channel_id,
          user_id: params!.user_id,
        },
        query: {
          role: role!,
        },
      },
    }) satisfies EventPayload<
      typeof changeChannelParticipantRoleMutation.start
    >,
  target: changeChannelParticipantRoleMutation.start,
})

sample({
  clock: [
    changeChannelParticipantRoleSuccessFinished,
    changeChannelParticipantRoleCanceled,
  ],
  target: [
    changeChannelParticipantRoleModal.close,
    changeChannelParticipantRoleMutation.reset,
    selectedRole.reset,
    $selectedChannelParticipantToChangeRole.reinit,
  ] as const,
})
