import { createMutation } from '@farfetched/core'
import type { EventPayload } from 'effector'
import { combine, createEvent, restore, sample } from 'effector'
import { deleteChannelParticipantRoleBaseFx } from '~/api/manage.effects'
import type { ApiType } from '~/api/types'
import { createDisclosureAtomImpl } from '~/shared/lib/factories/create-disclosure'
import { createStateOld } from '~/shared/lib/factories/create-state.old'

export const deleteParticipantModal = createDisclosureAtomImpl()

export const deleteParticipantClicked = createEvent<{
  user_id: ApiType<'AccountRoleSchema'>['id']
  name: ApiType<'AccountRoleSchema'>['name']
  channel_id: ApiType<'ChannelSchema'>['channel']['id']
}>()

export const $participantToDelete = restore(deleteParticipantClicked, null)
export const participantName = createStateOld({ initial: '' })

export const $allowDeleteRole = combine(
  [$participantToDelete, participantName.$value],
  ([participant, name]) =>
    participant?.name?.toLocaleLowerCase() === name.toLocaleLowerCase(),
)

export const deleteParticipantConfirmed = createEvent()

export const deleteParticipantCanceled = createEvent()

export const deleteParticipantMutation = createMutation({
  effect: deleteChannelParticipantRoleBaseFx,
})

export const channelParticipantSuccessDeleted =
  deleteParticipantMutation.finished.success

sample({
  clock: deleteParticipantClicked,
  target: deleteParticipantModal.open,
})

sample({
  clock: deleteParticipantConfirmed,
  source: { participant: $participantToDelete, allow: $allowDeleteRole },
  filter: ({ participant, allow }) => !!participant && allow,
  fn: ({ participant }) =>
    ({
      params: {
        path: {
          channel_id: participant!.channel_id,
          user_id: participant!.user_id,
        },
      },
    }) satisfies EventPayload<typeof deleteParticipantMutation.start>,
  target: deleteParticipantMutation.start,
})

sample({
  clock: [channelParticipantSuccessDeleted, deleteParticipantCanceled],
  target: [
    deleteParticipantModal.close,
    $participantToDelete.reinit,
    deleteParticipantMutation.reset,
    participantName.reset,
  ] as const,
})
