import type { ApiType } from '~/api/types'

const record: Record<ApiType<'Roles'>, string> = {
  admin: 'Администратор',
  analytic: 'Аналитик',
  owner: 'Владелец',
  redactor: 'Редактор',
  superadmin: 'Суперадмин',
}

export function translateRole(role: ApiType<'Roles'>) {
  return record[role]
}
